import provideState_QdJe8OSSD_8wGCIAC_njNx2RCgNRiM6pPcjiRmvk3ic from "/home/library/www/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/library/www/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/library/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/home/library/www/frontend/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin__8RMJ40bEfEfGPP9lUCIe_7glhOddyABjNbkp4PIy9E from "/home/library/www/frontend/.nuxt-eb/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/library/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68 from "/home/library/www/frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import language_eYKsUBVhhbAQxkgEQfGaQ9TEZ_6EZsjgtNqWUANErGs from "/home/library/www/frontend/node_modules/nuxt-language-negotiation/dist/runtime/plugins/language.mjs";
import useDirectives_NcsmbTFtuxtImPw8cEl5AO8qO7rGGioptu5gcoSiIEg from "/home/library/www/frontend/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.js";
import createBootstrap_hVHJTLgpv_XG80ZrB1jkQ1I94tXJeqHUHoK7fbPy5Wg from "/home/library/www/frontend/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.js";
import graphqlMiddleware_iWFzsP0MfTNrSebqRVAXpE_gZdmmQS8KQ8EW3l_Xcog from "/home/library/www/frontend/plugins/graphqlMiddleware.ts";
import texts_lnAjn_dGKwYBMfpVPhpA1VKHaMBwEGKQeF7B1giuAoA from "/home/library/www/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
import drupalUser_csK0kmJtoe8MCRdEph29jVmq41JxUUPSrLlC7fbBQ0A from "/home/library/www/frontend/plugins/drupalUser.ts";
export default [
  provideState_QdJe8OSSD_8wGCIAC_njNx2RCgNRiM6pPcjiRmvk3ic,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin__8RMJ40bEfEfGPP9lUCIe_7glhOddyABjNbkp4PIy9E,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_cWQMPgYRW0BWZIw2GExtJ2gkroIp4Vr3ZCOt1HoJd68,
  language_eYKsUBVhhbAQxkgEQfGaQ9TEZ_6EZsjgtNqWUANErGs,
  useDirectives_NcsmbTFtuxtImPw8cEl5AO8qO7rGGioptu5gcoSiIEg,
  createBootstrap_hVHJTLgpv_XG80ZrB1jkQ1I94tXJeqHUHoK7fbPy5Wg,
  graphqlMiddleware_iWFzsP0MfTNrSebqRVAXpE_gZdmmQS8KQ8EW3l_Xcog,
  texts_lnAjn_dGKwYBMfpVPhpA1VKHaMBwEGKQeF7B1giuAoA,
  drupalUser_csK0kmJtoe8MCRdEph29jVmq41JxUUPSrLlC7fbBQ0A
]